import {
  Button,
  createStyles,
  Group,
  IconButton,
  Indicator,
  Transition,
  useMantineTheme,
} from 'ui/core'
import {AppHeader as CoreAppHeader, BrandLink, UserAvatar} from 'core/components'
import {ProductSearch} from 'components'
import {ReactNode, useMemo} from 'react'
import {useAuth} from 'hooks/use-auth'
import Link from 'next/link'
import {theme as uiTheme} from 'core/utils'
import {useMediaQuery} from 'ui/hooks'
import RightMenu from '../RightMenu'

const useStyles = createStyles((_, {home}: {home: boolean}) => ({
  header: home
    ? {
        backgroundColor: uiTheme.colors.secondary.waterBlue,
        borderBottom: '0',
      }
    : {},
}))

export interface AppHeaderProps {
  showSearch: boolean
  home: boolean
  shop?: boolean
  rightSection?: ReactNode
}

const AppHeader = ({showSearch, home, shop, rightSection}: AppHeaderProps) => {
  const {classes} = useStyles({home})
  const {loading, user, requireAuth} = useAuth()
  const theme = useMantineTheme()
  const isXs = useMediaQuery(theme.fn.smallerThan('sm').replace('@media', ''), true, {
    getInitialValueInEffect: false,
  })

  const shopButton = (
    <Indicator key="app-layout-shop-icon" inline label="New" color="red" size={20} offset={4}>
      <IconButton
        component={Link}
        href="/shop"
        name="buildingStore"
        variant="default"
        size="xl"
        aria-label="Shop"
      />
    </Indicator>
  )

  const right = useMemo(() => {
    if (loading) return null

    const items = []

    if (isXs) {
      if (user && !rightSection) {
        items.push(<UserAvatar key="app-layout-user-avatar" />)
      } else if (!user && !rightSection) {
        items.push(shopButton)
      }
    } else {
      if (!rightSection) {
        items.push(shopButton)
      }
      items.push(
        user ? (
          <RightMenu key="app-layout-right-menu" />
        ) : (
          <Button key="app-layout-get-started" onClick={requireAuth}>
            Get Started
          </Button>
        ),
      )
    }

    if (rightSection) {
      items.push(<div key="app-layout-right-section">{rightSection}</div>)
    }

    return (
      <Group spacing="md" align="center">
        {items}
      </Group>
    )
  }, [loading, user, isXs, rightSection])

  const center = (
    <Transition duration={100} mounted={showSearch} transition="slide-up">
      {(styles) => (
        <div style={styles}>
          <ProductSearch
            triggerProps={{
              variant: 'segment',
              onlyType: shop ? 'Buy' : undefined,
              onlyTypeLabel: shop ? 'Search' : undefined,
            }}
          />
        </div>
      )}
    </Transition>
  )

  const left = <BrandLink withTitle={!isXs} />

  return (
    <CoreAppHeader
      leftSection={left}
      centerSection={center}
      rightSection={right}
      className={classes.header}
    />
  )
}

export default AppHeader
