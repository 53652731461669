import {Carousel, CarouselProps} from 'ui/carousel'
import {WheelGesturesPlugin} from 'embla-carousel-wheel-gestures'
import {ReactNode, useRef} from 'react'
import {useId} from 'ui/hooks'
import {formatTestID} from 'core/utils'

interface RowCarouselProps<T> extends Omit<CarouselProps, 'children'> {
  items: T[]
  children: (item: T) => ReactNode
  testID?: string
}

const RowCarousel: <T>(props: RowCarouselProps<T>) => JSX.Element = ({
  items,
  children,
  testID,
  ...rest
}) => {
  const carouselId = useId()
  const wheelGesturesRef = useRef(WheelGesturesPlugin())
  return (
    <Carousel
      data-testid={testID}
      withIndicators={false}
      slideGap="md"
      slideSize="100%"
      dragFree
      withControls
      containScroll="trimSnaps"
      nextControlLabel="Next"
      previousControlLabel="Previous"
      breakpoints={[
        {minWidth: 'xs', slideSize: `${100 / 2}%`},
        {minWidth: 'md', slideSize: `${100 / 3}%`},
        {minWidth: 'lg', slideSize: `${100 / 4}%`},
      ]}
      {...rest}
      plugins={[wheelGesturesRef.current]}
      styles={{
        viewport: {
          margin: '-8px',
          padding: '8px',
        },
        control: {
          '&[data-inactive]': {
            opacity: 0,
            cursor: 'default',
          },
        },
      }}
    >
      {items.map((item, index) => (
        <Carousel.Slide
          key={`${carouselId}-${index}`}
          data-testid={formatTestID(testID, `slide-${index}`)}
        >
          {children(item)}
        </Carousel.Slide>
      ))}
    </Carousel>
  )
}

export default RowCarousel
