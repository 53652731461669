import {createSafeContext} from 'ui/utils'

export type ScrollAreaComponent = React.FC<any>

interface ProductSearchContext {
  isOpen: boolean
  openSearch: () => void
  closeSearch: () => void
  scrollAreaComponent?: ScrollAreaComponent
}

export const [ProductSearchProvider, useProductSearchContext] =
  createSafeContext<ProductSearchContext>('ProductSearch component was not found in tree')
