import {Skeleton, Card, Group} from 'ui/core'

const ActivityCardLoading = () => (
  <Card sx={{margin: '12px 0'}}>
    <Group position="apart">
      <Skeleton height={20} width="30%" radius="xl" />
      <Skeleton height={20} width="15%" radius="xl" />
    </Group>
  </Card>
)

export default ActivityCardLoading
