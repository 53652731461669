import {useUpdateSellRequest} from '@resellam/sell-request'
import {ProductPartsField} from 'components'
import {useNotifications, useForm} from 'core/hooks'
import {SellRequest, SellRequestIssue} from 'model'
import {Button, ModalActions, Box, LoadingOverlay, Accordion} from 'ui/core'
import {zodResolver} from 'ui/form'
import {z} from 'zod'

const issueSchema = (message: string) =>
  z
    .object({
      part: z.object({
        id: z.string(),
        name: z.string(),
      }),
      description: z.string().optional(),
    })
    .array()
    .min(1, {message})
    .optional()

const schema = z
  .object({
    damages: issueSchema('Select damaged parts'),
    repairs: issueSchema('Select repaired parts'),
  })
  .strict()

interface IssuesFormProps {
  sellRequest: SellRequest
  onCancel: () => void
  onSuccess: (data: {repairs?: SellRequestIssue[]; damages?: SellRequestIssue[]}) => void
}

const IssuesForm = ({sellRequest, onCancel, onSuccess}: IssuesFormProps) => {
  const notifications = useNotifications()
  const [updateSellRequest, updateSellRequestState] = useUpdateSellRequest()

  const form = useForm({
    initialValues: {
      repairs: sellRequest.repairs,
      damages: sellRequest.damages,
    },
    validate: zodResolver(schema),
  })

  const submit = async () => {
    const {repairs, damages} = form.values
    if (form.validate().hasErrors) return
    const result = await updateSellRequest({id: sellRequest.id, repairs, damages})
    notifications.show({
      variant: result ? 'success' : 'error',
      action: 'update',
      entity: 'sell request',
    })
    result && onSuccess({repairs, damages})
  }

  return (
    <Box sx={{position: 'relative'}}>
      <LoadingOverlay visible={updateSellRequestState.isRunning} />
      <Accordion multiple variant="separated" defaultValue={['damages', 'repairs']}>
        <Accordion.Item value="damages">
          <Accordion.Control>Select damaged parts</Accordion.Control>
          <Accordion.Panel>
            <ProductPartsField
              withinPortal
              maxCols={3}
              {...form.getInputProps('damages')}
              product={sellRequest.product}
            />
          </Accordion.Panel>
        </Accordion.Item>
        <Accordion.Item value="repairs">
          <Accordion.Control>Select repaired parts</Accordion.Control>
          <Accordion.Panel>
            <ProductPartsField
              withinPortal
              maxCols={3}
              {...form.getInputProps('repairs')}
              product={sellRequest.product}
            />
          </Accordion.Panel>
        </Accordion.Item>
      </Accordion>
      <ModalActions>
        <Button variant="default" onClick={onCancel}>
          Cancel
        </Button>
        <Button
          type="submit"
          onClick={submit}
          disabled={!form.isDirty()}
          loading={updateSellRequestState.isRunning}
        >
          Save
        </Button>
      </ModalActions>
    </Box>
  )
}

export default IssuesForm
