import {CatalogueCard, CatalogueCardProps} from 'components'
import {Image} from 'core/components'
import {useBreakpoint} from 'core/hooks'
import {ProductPart} from 'model'
import {forwardRef} from 'react'
import {Text, Stack, UnstyledButton} from 'ui/core'

interface ProductPartCardProps extends Omit<CatalogueCardProps, 'children'> {
  productPart: ProductPart
  selected?: boolean
}

const ProductPartCard = forwardRef<HTMLButtonElement, ProductPartCardProps>(
  ({productPart, selected, ...rest}, ref) => {
    const {isXs} = useBreakpoint()
    return (
      <CatalogueCard
        {...rest}
        ref={ref}
        scale={false}
        component={UnstyledButton}
        sx={(theme) => ({
          '&[data-with-border]': {
            border: selected ? `2px solid ${theme.colors.gray[9]}` : undefined,
          },
        })}
      >
        <Stack align="center" spacing="sm">
          <Image
            unoptimized
            src={productPart.image}
            alt={productPart.name}
            width={isXs ? 80 : 100}
            height={isXs ? 80 : 100}
          />
          <Text align="center">{productPart.name}</Text>
        </Stack>
      </CatalogueCard>
    )
  },
)

export default ProductPartCard
