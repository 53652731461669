import {SearchType} from '../types'
import {useProductSearch} from '../useProductSearch'
import ProductSearchTriggerPill from './ProductSearchTriggerPill'
import ProductSearchTriggerSegment from './ProductSearchTriggerSegment'

export interface ProductSearchTriggerProps {
  id?: string
  onlyType?: SearchType
  onlyTypeLabel?: string
  variant?: 'pill' | 'segment'
  onTrigger: () => void
}

const ProductSearchTrigger = ({
  id,
  variant = 'segment',
  onlyType,
  onlyTypeLabel,
  onTrigger,
}: ProductSearchTriggerProps) => {
  const {searchType, setSearchType} = useProductSearch()

  const trigger = (type: SearchType) => {
    setSearchType(type)
    onTrigger()
  }

  return variant === 'pill' ? (
    <ProductSearchTriggerPill id={id} onChange={trigger} />
  ) : (
    <ProductSearchTriggerSegment
      id={id}
      onlyType={onlyType}
      onlyTypeLabel={onlyTypeLabel}
      value={searchType}
      onChange={trigger}
    />
  )
}

export default ProductSearchTrigger
