import {Catalogue} from 'components'
import {PRODUCT_PART_FIELDS, getSanityClient} from '@resellam/sanity'
import {Product, ProductPart, SellRequestIssue} from 'model'
import {ProductPartCard} from 'components/ProductPartCard'
import {
  Button,
  Center,
  FormField,
  FormFieldProps,
  Loader,
  Popover,
  ScrollArea,
  Textarea,
} from 'ui/core'
import {useState} from 'react'
import {formatTestID} from 'core/utils'
import {useQueryDocuments} from '@aonawale/react-sanity-hooks'
import {filter} from '@aonawale/sanity-query'

const otherPart: ProductPart = {
  id: 'OTHERS',
  name: 'Others',
}

interface ProductPartsFieldProps extends Omit<FormFieldProps, 'onChange' | 'children'> {
  trapFocus?: boolean
  withinPortal?: boolean
  product?: Product
  value?: SellRequestIssue[]
  onChange?: (value: SellRequestIssue[]) => void
  testID?: string
  maxCols?: number
}

const ProductPartsField = ({
  product,
  value = [],
  trapFocus,
  withinPortal,
  onChange,
  maxCols,
  testID,
  ...rest
}: ProductPartsFieldProps) => {
  const selected = new Set(value.map(({part}) => part.id))
  const [othersOpened, setOthersOpened] = useState(false)

  const {data: parts, isLoading} = useQueryDocuments<ProductPart>(
    getSanityClient(),
    product?.category?.id
      ? {
          type: 'productPart',
          constraints: [
            filter(`references(*[_type=="category" && _id == "${product?.category?.id}"]._id)`),
          ],
        }
      : undefined,
    PRODUCT_PART_FIELDS,
  )

  const handleSelect = (part: ProductPart) => {
    if (selected.has(part.id)) {
      onChange?.(value.filter((item) => part.id !== item.part.id))
    } else {
      onChange?.([...value, {part}])
    }
  }

  const scrollHeight = typeof window === 'undefined' ? 400 : window.innerHeight / 2

  if (isLoading)
    return (
      <Center>
        <Loader />
      </Center>
    )

  return (
    <FormField {...rest} testID={testID}>
      <ScrollArea.Autosize mah={scrollHeight} type="hover">
        <Catalogue
          cols={2}
          breakpoints={[
            {minWidth: 'xs', cols: 3},
            {minWidth: 'sm', cols: 4},
            {minWidth: 'md', cols: Math.min(maxCols || 5, 5)},
          ]}
        >
          {parts?.map((part) => (
            <ProductPartCard
              key={part.id}
              productPart={part}
              selected={selected.has(part.id)}
              onClick={() => handleSelect(part)}
              testID={formatTestID(testID, part.id)}
            />
          ))}
          <Popover
            withArrow
            width={350}
            closeOnClickOutside
            closeOnEscape
            trapFocus={trapFocus}
            withinPortal={withinPortal}
            position="top"
            opened={othersOpened}
            onChange={setOthersOpened}
          >
            <Popover.Target>
              <ProductPartCard
                productPart={otherPart}
                selected={selected.has(otherPart.id)}
                onClick={() => {
                  handleSelect(otherPart)
                  setOthersOpened(!selected.has(otherPart.id))
                }}
                data-testid={formatTestID(testID, 'others')}
              />
            </Popover.Target>
            <Popover.Dropdown>
              <Textarea
                autoFocus
                placeholder="Describe others"
                onChange={(e) => {
                  const index = value?.findIndex(({part}) => part.id === otherPart.id) ?? -1
                  if (index === -1 || !value) return
                  const newValues = [...value]
                  newValues[index].description = e.target.value
                  onChange?.(newValues)
                }}
              />
              <Button
                fullWidth
                mt="md"
                size="sm"
                variant="light"
                onClick={() => setOthersOpened(false)}
              >
                Save
              </Button>
            </Popover.Dropdown>
          </Popover>
        </Catalogue>
      </ScrollArea.Autosize>
    </FormField>
  )
}

export default ProductPartsField
