import {forwardRef} from 'react'
import {createPolymorphicComponent, createStyles, Card, CardProps} from 'ui/core'

const useStyles = createStyles((theme, {scale}: {scale?: boolean}) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    transition: 'box-shadow 150ms ease, transform 100ms ease',
    [theme.fn.largerThan('sm')]: {
      '&:hover, &:focus': {
        boxShadow: `${theme.shadows.md} !important`,
        transform: scale ? 'scale(1.05)' : undefined,
      },
    },
  },
}))

export interface CatalogueCardProps extends CardProps {
  onClick?: () => void
  scale?: boolean
  testID?: string
}

const CatalogueCard = forwardRef<HTMLDivElement, CatalogueCardProps>(
  ({children, className, scale = true, testID, ...rest}, ref) => {
    const {classes, cx} = useStyles({scale})
    return (
      <Card p="md" className={cx(classes.root, className)} data-testid={testID} {...rest} ref={ref}>
        {children}
      </Card>
    )
  },
)

export default createPolymorphicComponent<'div', CatalogueCardProps>(CatalogueCard)
