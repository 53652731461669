import {ProductAutocomplete} from 'components'
import {Center, Loader, Stack} from 'ui/core'
import {forwardRef, useEffect, useImperativeHandle, useMemo, useState} from 'react'
import {Product} from 'model'
import {PRODUCT_FIELDS, getSanityClient} from '@resellam/sanity'
import {useForm} from 'core/hooks'
import {z} from 'zod'
import {zodResolver} from 'ui/form'
import {formatTestID, getProductVariants, variantsSchema, getFormVariants} from 'core/utils'
import {ProductVariantInput, ProductDetailWrapper} from 'core/components'
import {useGetDocument} from '@aonawale/react-sanity-hooks'
import {UpgradeData} from '../types'

export interface UpgradeProps {
  onLoading: (isLoading: boolean) => void
  initialValues?: UpgradeData
  testID?: string
}

export interface UpgradeRef {
  validate: () => {hasErrors: boolean}
  values: () => UpgradeData | undefined
  safeValues: () => UpgradeData | undefined
}

const Upgrade = forwardRef<UpgradeRef, UpgradeProps>(({onLoading, initialValues, testID}, ref) => {
  const [title, setTitle] = useState<string>()
  const [productHit, setProductHit] = useState<{objectID: string; [key: string]: any} | undefined>(
    initialValues?.product?.id
      ? {
          objectID: initialValues?.product.id,
          ...initialValues.product,
        }
      : undefined,
  )
  const {data: product, isLoading} = useGetDocument<Product>(
    getSanityClient(),
    productHit?.objectID ? {id: productHit?.objectID} : undefined,
    PRODUCT_FIELDS,
  )

  const variants = getProductVariants(product?.variants)

  const schema = useMemo(
    () =>
      z
        .object({
          variants: variantsSchema(product?.variants),
        })
        .strict(),
    [product],
  )

  const form = useForm<Partial<Omit<UpgradeData, 'variants'>> & {variants: Record<string, string>}>(
    {
      initialValues: {
        variants: getFormVariants(product?.variants, initialValues?.variants),
      },
      validate: zodResolver(schema),
    },
  )

  const getValue = (values: Partial<UpgradeData>): UpgradeData => ({
    product,
    productId: product?.id,
    variants: values.variants,
  })

  useImperativeHandle(
    ref,
    () => ({
      validate: () => (product ? form.validate() : {hasErrors: false}),
      safeValues: () => {
        if (!product) return undefined
        const parsed = schema.safeParse(form.values)
        return getValue(parsed.success ? parsed.data : {})
      },
      values: () => {
        if (product) return getValue(schema.parse(form.values))
        if (title) return {title}
        return undefined
      },
    }),
    [form, product],
  )

  useEffect(() => {
    onLoading(isLoading)
  }, [isLoading])

  return (
    <Stack spacing="sm" data-testid={testID}>
      <ProductAutocomplete
        size="lg"
        labelProps={{size: 'md'}}
        value={productHit?.title}
        placeholder="iPhone 14 Pro Max"
        label="What would you like to upgrade to?"
        onInput={(e) => setTitle(e.currentTarget.value)}
        onChange={(hit) => {
          setProductHit(hit || undefined)
          form.reset()
        }}
        testID={formatTestID(testID, 'product-autocomplete')}
      />

      {productHit && isLoading && (
        <Center p="xl">
          <Loader data-testid={formatTestID(testID, 'loader')} />
        </Center>
      )}

      {product && (
        <ProductDetailWrapper
          product={product}
          imageSize={280}
          testID={formatTestID(testID, 'product-detail')}
        >
          {variants?.length ? (
            <Stack spacing="sm">
              {variants.map((variant) => (
                <ProductVariantInput
                  key={variant.label}
                  {...variant}
                  {...form.getInputProps(`variants.${variant.label}`)}
                  required
                  testID={formatTestID(testID, variant.label, 'product-variant-input')}
                />
              ))}
            </Stack>
          ) : null}
        </ProductDetailWrapper>
      )}
    </Stack>
  )
})

export default Upgrade
