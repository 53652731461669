import {forwardRef, useEffect, useMemo, useState} from 'react'
import {useDebouncedValue} from 'ui/hooks'
import {AutocompleteProps, Autocomplete, Loader, Icon} from 'ui/core'
import {NoResults} from 'core/components'
import {AlgoliaProductHit} from 'lib/algolia'
import {useProductSearch} from '../useProductSearch'

interface ProductAutocompleteProps extends Omit<AutocompleteProps, 'data' | 'onChange'> {
  onChange?: (product: AlgoliaProductHit | null) => void
  testID?: string
}

const ProductAutocomplete = forwardRef<HTMLInputElement, ProductAutocompleteProps>(
  ({value: initalValue, onChange, testID, ...rest}, ref) => {
    const {autocomplete, status, products} = useProductSearch()
    const [value, setValue] = useState(initalValue)
    const [query] = useDebouncedValue(value, 200)
    const loading = status === 'loading'

    useEffect(() => {
      autocomplete.setIsOpen(true)
    }, [])

    useEffect(() => {
      if (!query) return
      autocomplete.setQuery(query)
      autocomplete.refresh()
    }, [query])

    const items = products?.items || []
    const autocompleteData = useMemo(
      () => items.slice(0, 5).map((product) => ({value: product.title, product})),
      [items],
    )

    const icon = useMemo(() => {
      if (loading) return <Loader size="sm" color="gray" />
      return <Icon name="search" />
    }, [loading])

    const nothingFound = value && !items.length && query && !loading

    return (
      <Autocomplete
        ref={ref}
        withinPortal
        dropdownPosition="bottom"
        {...rest}
        value={value}
        icon={icon}
        onChange={(val) => {
          onChange?.(null)
          setValue(val)
        }}
        data={autocompleteData || []}
        filter={() => true}
        limit={5}
        nothingFound={nothingFound ? <NoResults query={query} /> : null}
        onItemSubmit={({product}) => onChange?.(product)}
        data-lpignore="true"
        data-testid={testID}
      />
    )
  },
)

export default ProductAutocomplete
