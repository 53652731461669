import {Button, Stack} from 'ui/core'
import {useCart} from 'lib/cart'
import {Money} from 'core/components'
import Link from 'next/link'

const FloatingCartHeader = () => {
  const cart = useCart({checkout: true})

  return (
    <Stack spacing="xs" align="center">
      <Money value={cart.total.price} inline weight="bold" align="center" size="md" />
      <Button
        component={Link}
        href="/shop/cart"
        compact
        variant="default"
        onClick={() => cart.close()}
      >
        Checkout
      </Button>
    </Stack>
  )
}

export default FloatingCartHeader
