import React, {forwardRef} from 'react'
import {useComponentDefaultProps} from 'ui/styles'
import {ModalBase, ModalBaseContentProps} from 'ui/core'
import {AlgoliaProductHit} from 'lib/algolia'
import analytics from 'lib/analytics'
import {useRouter} from 'next/router'
import {useProductSearchContext, ScrollAreaComponent} from '../ProductSearch.context'
import {ProductSearchForm} from '../ProductSearchForm'
import {useProductSearch} from '../useProductSearch'
import {getProductURL} from '../utils'
import useStyles from './ProductSearchContent.style'

export interface ProductSearchContentProps extends ModalBaseContentProps {
  scrollAreaComponent?: ScrollAreaComponent
}

const defaultProps: Partial<ProductSearchContentProps> = {
  radius: 'lg',
}

export const ProductSearchContent = forwardRef<HTMLElement, ProductSearchContentProps>(
  (props, ref) => {
    const {className, ...others} = useComponentDefaultProps('ModalContent', defaultProps, props)

    const ctx = useProductSearchContext()
    const {autocomplete, searchType} = useProductSearch()
    const router = useRouter()
    const {classes, cx} = useStyles()

    const handleProductClick = (hit: AlgoliaProductHit) => {
      const eventName = searchType === 'Sell' ? 'view_product' : 'view_shop_product'
      analytics.track(eventName, {
        category: 'engagement',
        product_name: hit.title,
        product_brand_name: hit.brand?.title,
        product_category_name: hit.category?.title,
      })

      const url = getProductURL(searchType, hit)
      const isShop = router.pathname === '/shop'

      if (isShop) {
        router.push(url, undefined, {shallow: true})
      } else {
        router.push(url)
      }
      ctx.closeSearch()
    }

    return (
      <ModalBase.Content ref={ref} className={cx(classes.content, className)} {...others}>
        <ModalBase.Body>
          <div {...autocomplete.getRootProps({})}>
            <ProductSearchForm onClickProduct={handleProductClick} onCancel={ctx.closeSearch} />
          </div>
        </ModalBase.Body>
      </ModalBase.Content>
    )
  },
)
