import {Menu, Divider, Icon} from 'ui/core'
import {useAuth} from '@resellam/auth'
import {RightMenu as CoreRightMenu} from 'core/components'
import useLiveChat from 'hooks/use-live-chat'
import {useQueryNotifications} from 'lib/notification'
import {useState} from 'react'
import Link from 'next/link'
import NotificationsModal from '../NotificationsModal'
import NotificationsIndicator from '../NotificationsIndicator'

const RightMenu = () => {
  const {signout} = useAuth()
  const liveChat = useLiveChat()
  const {data} = useQueryNotifications()
  const [isExpanded, setIsExpanded] = useState(false)
  const notificationsCount = data?.length || 0

  return (
    <>
      <CoreRightMenu
        indicatorProps={{
          label: notificationsCount && notificationsCount > 9 ? `9+` : notificationsCount,
          disabled: !notificationsCount,
          offset: 8,
        }}
      >
        <Menu.Item icon={<Icon name="shoppingBag" color="gray" />} href="/orders" component={Link}>
          Orders
        </Menu.Item>
        <Menu.Item
          icon={<Icon name="layoutList" color="gray" />}
          href="/sell-requests"
          component={Link}
        >
          Sell requests
        </Menu.Item>
        <Menu.Item icon={<Icon name="bell" color="gray" />} onClick={() => setIsExpanded(true)}>
          <NotificationsIndicator variant="dot">Notifications</NotificationsIndicator>
        </Menu.Item>
        <Menu.Item
          icon={<Icon name="settings" color="gray" />}
          href="/settings/profile"
          component={Link}
        >
          Settings
        </Menu.Item>
        <Divider />
        <Menu.Item
          key="menu-help"
          icon={<Icon name="help" color="gray" />}
          onClick={() => liveChat.open()}
        >
          Help
        </Menu.Item>
        <Menu.Item icon={<Icon name="logout" color="gray" />} onClick={signout}>
          Log out
        </Menu.Item>
      </CoreRightMenu>
      <NotificationsModal opened={isExpanded} onClose={() => setIsExpanded(false)} />
    </>
  )
}

export default RightMenu
