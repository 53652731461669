import {AppShellProps, createStyles} from 'ui/core'
import {AppLayout as CoreAppLayout} from 'core/components'
import {useRouter} from 'next/router'
import {useBreakpoint} from 'core/hooks'
import {ReactNode, useEffect, useState} from 'react'
import {WELCOME_PAGE_PRODUCTS_SEARCH_ID} from 'helpers/constants'
import {useAuth} from 'hooks/use-auth'
import AppFooter from './AppFooter'
import BottomTabs from './BottomTabs'
import InstallPrompt from './InstallPrompt'
import {AppHeader} from './AppHeader'

const useStyles = createStyles((theme, {home}: {home: boolean}) => ({
  root: home
    ? {
        main: {
          paddingTop: '80px',
          paddingBottom: '40px',
          [theme.fn.smallerThan('sm')]: {},
        },
      }
    : {
        main: {
          paddingBottom: '40px',
        },
      },
  footer: {
    paddingTop: '24px',
    paddingBottom: '24px',
    [theme.fn.smallerThan('xs')]: {
      paddingBottom: '120px',
    },
  },
}))

export interface AppLayoutProps extends AppShellProps {
  rightSection?: ReactNode
}

const AppLayout = ({rightSection, children}: AppLayoutProps) => {
  const router = useRouter()
  const isHome = router.pathname === '/'
  const isShop = router.pathname.startsWith('/shop')
  const {classes} = useStyles({home: isHome})
  const {isXs} = useBreakpoint()
  const {user} = useAuth()
  const [showSearch, setShowSearch] = useState(false)

  useEffect(() => {
    const search = document.getElementById(WELCOME_PAGE_PRODUCTS_SEARCH_ID)
    if (!search) return () => {}

    const observer = new IntersectionObserver(([entry]) => setShowSearch(!entry.isIntersecting), {
      root: null,
      threshold: 1,
      rootMargin: '0px',
    })

    observer.observe(search)
    return () => observer.unobserve(search)
  }, [isHome, user])

  return (
    <CoreAppLayout
      className={classes.root}
      footer={<AppFooter className={classes.footer} />}
      header={
        <AppHeader
          rightSection={rightSection}
          home={isHome}
          shop={isShop}
          showSearch={!isHome || showSearch}
        />
      }
    >
      {children}
      {isXs && user ? <BottomTabs /> : null}
      <InstallPrompt />
    </CoreAppLayout>
  )
}

export default AppLayout
