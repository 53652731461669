import {
  Button,
  Group,
  Icon,
  IconButton,
  Loader,
  Menu,
  TextInput,
  TextInputProps,
  ThemeIcon,
} from 'ui/core'
import {forwardRef, useImperativeHandle, useRef} from 'react'
import {useBreakpoint} from 'core/hooks'
import {useProductSearch} from '../useProductSearch'
import {SearchType} from '../types'

interface ProductSearchInputProps extends TextInputProps {
  onCancel?: () => void
}

const ProductSearchInput = forwardRef<HTMLInputElement, ProductSearchInputProps>(
  ({onCancel, ...rest}, ref) => {
    const {autocomplete, searchType, setSearchType, query, status} = useProductSearch()
    const {isXs} = useBreakpoint()
    const inputRef = useRef<HTMLInputElement>(null!)
    const stalled = status === 'stalled'
    const loading = status === 'loading'

    useImperativeHandle(ref, () => inputRef?.current, [inputRef])

    const changeSearchType = (type: SearchType) => {
      setSearchType(type)
      inputRef.current?.focus()
    }

    const menuTrigger = isXs ? (
      <ThemeIcon size="lg" variant="filled" color="#fff" radius="xl">
        <Icon name="chevronDown" size="md" color="blue" />
      </ThemeIcon>
    ) : (
      <Button
        radius="xl"
        size="sm"
        color="dark"
        variant="white"
        rightIcon={<Icon name="chevronDown" />}
        styles={{rightIcon: {marginLeft: 4}}}
      >
        {searchType}
      </Button>
    )

    const menu = (
      <Menu withinPortal shadow="md" width={100} position="bottom-end">
        <Menu.Target>{menuTrigger}</Menu.Target>
        <Menu.Dropdown>
          <Menu.Item onClick={() => changeSearchType('Buy')}>Buy</Menu.Item>
          <Menu.Item onClick={() => changeSearchType('Sell')}>Sell</Menu.Item>
        </Menu.Dropdown>
      </Menu>
    )

    const clearButton = query ? (
      <IconButton
        name="x"
        size="md"
        variant="outline"
        onClick={() => {
          autocomplete.setQuery('')
          autocomplete.refresh()
          inputRef.current?.focus()
        }}
      />
    ) : null

    const closeButton = (
      <Button compact variant="subtle" onClick={onCancel}>
        Cancel
      </Button>
    )

    return (
      <Group noWrap spacing={4}>
        <TextInput
          {...(autocomplete.getInputProps({} as any) as any)}
          {...rest}
          w="100%"
          ref={inputRef}
          variant="filled"
          size="lg"
          radius="xl"
          autoFocus
          iconWidth={42}
          placeholder={`What are you ${searchType === 'Sell' ? 'selling' : 'buying'}?`}
          styles={{rightSection: {justifyContent: 'flex-end', right: 8}}}
          icon={
            loading || stalled ? (
              <Loader size="xs" color="gray" />
            ) : (
              <Icon name="search" color="blue" />
            )
          }
          rightSection={
            <Group noWrap spacing={8} align="center" position="right">
              {clearButton}
              {menu}
            </Group>
          }
        />
        {closeButton}
      </Group>
    )
  },
)

export default ProductSearchInput
