import {Stack, Text, Title} from 'ui/core'
import {useSellRequest} from '@resellam/sell-request'
import {useState} from 'react'
import {formatTestID} from 'core/utils'
import {SellRequestCountdown} from '../SellRequestCountdown'
import {SellRequestSelectedOffer, SellRequestSelectedOfferData} from '../SellRequestSelectedOffer'
import {SellRequestAcceptedOffer} from '../SellRequestAcceptedOffer'
import SellRequestSwapOffers from './SellRequestSwapOffers'

interface SellRequestAcceptedProps {
  testID?: string
}

const SellRequestAccepted = ({testID}: SellRequestAcceptedProps) => {
  const {sellRequest} = useSellRequest()
  const {offer, acceptedOffer, upgrade} = sellRequest || {}
  const [selectedOffer, setSelectedOffer] = useState<SellRequestSelectedOfferData | null>(null)

  return (
    <Stack data-testid={testID}>
      <div>
        <Title order={3} mb="xs" align="center">
          You have an offer!
        </Title>
        {!acceptedOffer && offer?.expiresAt && (
          <>
            <Text size="lg" weight={500} mb="xs" align="center">
              Sell{upgrade ? ' or Swap' : ''} your {sellRequest?.product?.title} before
            </Text>
            <SellRequestCountdown
              expiresAt={offer?.expiresAt}
              testID={formatTestID(testID, 'countdown')}
            />
          </>
        )}
      </div>

      {acceptedOffer && (
        <SellRequestAcceptedOffer testID={formatTestID(testID, 'accepted-offer')} />
      )}

      {!acceptedOffer && selectedOffer && (
        <SellRequestSelectedOffer
          onBack={() => setSelectedOffer(null)}
          offer={selectedOffer}
          testID={formatTestID(testID, 'selected-offer')}
        />
      )}

      {!acceptedOffer && !selectedOffer && (
        <SellRequestSwapOffers testID={testID} onSelecctedOffer={setSelectedOffer} />
      )}

      <Text size="sm" color="gray" data-testid={formatTestID(testID, 'disclaimer')}>
        Please note that the offer is subject to change based on how soon you bring the device to us
        and the condition matching the information you provided.
      </Text>
    </Stack>
  )
}

export default SellRequestAccepted
