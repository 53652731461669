import {formatTestID} from 'core/utils'
import {Button, ButtonProps, Group, GroupProps} from 'ui/core'

export interface StepNavButtonsProps
  extends Pick<ButtonProps, 'size'>,
    Omit<GroupProps, 'children'> {
  back?: ButtonProps & {onClick?: () => void}
  next: ButtonProps & {onClick?: () => void}
  testID?: string
}

const StepNavButtons = ({back, next, size = 'lg', testID, ...rest}: StepNavButtonsProps) => (
  <Group grow mt="xl" {...rest} data-testid={testID}>
    {back && (
      <Button size={size} {...back} variant="default" data-testid={formatTestID(testID, 'back')}>
        {back.children || 'Back'}
      </Button>
    )}
    <Button size={size} {...next} data-testid={formatTestID(testID, 'next')}>
      {next.children || 'Next'}
    </Button>
  </Group>
)

export default StepNavButtons
