import {Button, Icon, Text} from 'ui/core'
import {Dropzone, ProductPhotos} from 'core/components'
import {formatTestID} from 'core/utils'
import {Photo} from './types'
import PhotoCard from './PhotoCard'

interface MorePhotosProps {
  photos: Photo[]
  readonly?: boolean
  onDelete: (index: number, photo: Photo) => void
  onChange: (files: File[]) => void
  testID?: string
}

const MorePhotos = ({photos, readonly, testID, onDelete, onChange}: MorePhotosProps) => (
  <div data-testid={testID}>
    {photos.length ? (
      <Text mb="xs" caption>
        More photos
      </Text>
    ) : null}

    <ProductPhotos
      expanded
      photos={photos}
      renderPhoto={({photo, height, index}) =>
        photo.file ? (
          <PhotoCard
            key={`PhotoCard-${index}`}
            file={photo.file}
            label={photo.label}
            onDelete={readonly ? undefined : () => onDelete(index, photo)}
            height={height}
            testID={formatTestID(testID, 'photo-card', photo.label)}
          />
        ) : null
      }
    />

    {!readonly && (
      <Dropzone
        mt={photos.length ? 'md' : undefined}
        unstyled
        onDrop={onChange}
        testID={formatTestID(testID, 'add-more-photos')}
      >
        <Button leftIcon={<Icon name="photo" size="xl" />} size="md" variant="default" fullWidth>
          Add more photos
        </Button>
      </Dropzone>
    )}
  </div>
)

export default MorePhotos
