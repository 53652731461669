import {Box, BoxProps} from 'ui/core'

export interface HitListProps extends BoxProps {}

const HitList = ({children, ...rest}: HitListProps) => (
  <Box {...rest} component="ul" sx={{padding: 0, margin: 0, listStyleType: 'none'}}>
    {children}
  </Box>
)

export default HitList
