import {Select, SelectProps} from 'ui/core'
import {range} from 'ui/hooks'

interface QuantitySelectProps extends Omit<SelectProps, 'value' | 'onChange' | 'data'> {
  value?: number
  onChange?: (val: number) => void
}

const QuantitySelect = ({value, onChange, ...rest}: QuantitySelectProps) => (
  <Select
    sx={{maxWidth: '80px'}}
    placeholder="Quantity"
    {...rest}
    value={value ? value.toString() : null}
    onChange={(val) => (val ? onChange?.(parseInt(val, 10)) : null)}
    data={range(1, 10).map((e) => e.toString())}
  />
)

export default QuantitySelect
