import {Badge, Checkbox, Button, Stack, Group} from 'ui/core'
import {useRefinementList as useISRefinementList} from 'react-instantsearch-hooks-web'
import {ClearRefinement} from '../ClearRefinement'

export const useRefinementList = ({attribute}: RefinementListProps) => {
  const refinementList = useISRefinementList({
    attribute,
    limit: 7,
    showMore: true,
  })
  return refinementList
}

interface RefinementListProps {
  attribute: string
}

const RefinementList = ({attribute}: RefinementListProps) => {
  const refinementList = useRefinementList({
    attribute,
  })
  return (
    <div>
      {refinementList.items.find((item) => item.isRefined) && (
        <ClearRefinement attribute={attribute} />
      )}
      <Stack spacing="xs">
        {refinementList.items.map((item) => (
          <Group align="center" key={item.label}>
            <Checkbox
              value={item.value}
              checked={item.isRefined}
              label={item.label}
              sx={{lineHeight: 0}}
              onChange={(val) => refinementList.refine(val.target.value)}
            />
            <Badge variant="outline" color="gray">
              {item.count}
            </Badge>
          </Group>
        ))}
      </Stack>
      {refinementList.canToggleShowMore && (
        <Button
          p={0}
          compact
          size="sm"
          variant="white"
          onClick={() => refinementList.toggleShowMore()}
        >
          {refinementList.isShowingMore ? 'Show less' : 'Show more'}
        </Button>
      )}
    </div>
  )
}

export default RefinementList
