import {Button, ButtonProps, Card, Center, createStyles, Group, Icon, IconName} from 'ui/core'
import {rem} from 'ui/styles'
import {useBreakpoint} from 'core/hooks'
import {SearchIcon} from '../SearchIcon'
import {SearchType} from '../types'

const useStyles = createStyles((theme) => ({
  card: {
    backgroundColor: theme.white,
    width: '80%',
    display: 'flex',
    alignItems: 'center',
    gap: rem(16),
    justifyContent: 'space-between',
    [theme.fn.smallerThan('md')]: {
      width: '100%',
    },
  },
  group: {
    flexGrow: 1,
    gap: rem(8),
  },
}))

export interface ProductSearchTriggerPillProps {
  id?: string
  onChange?: (val: SearchType) => void
}

const TriggerButton = ({
  iconName,
  ...rest
}: ButtonProps & {iconName: IconName; onClick?: () => void}) => {
  const {isXs} = useBreakpoint()
  return (
    <Button
      fullWidth
      leftIcon={<Icon name={iconName} size={isXs ? 'md' : 'lg'} />}
      variant="default"
      sx={(theme) => ({
        height: 48,
        boxShadow: theme.shadows.xs,
        borderWidth: 0.5,
        color: theme.colors.blue[6],
        [theme.fn.largerThan('xs')]: {
          height: 52,
        },
      })}
      {...rest}
    />
  )
}

const ProductSearchTriggerPill = ({id, onChange}: ProductSearchTriggerPillProps) => {
  const {classes} = useStyles()
  const {isXs} = useBreakpoint()

  return (
    <Center>
      <Card id={id} p={8} className={classes.card} radius="xl">
        <Group className={classes.group} noWrap>
          <TriggerButton iconName="shoppingBag" onClick={() => onChange?.('Buy')}>
            Buy
          </TriggerButton>
          <TriggerButton iconName="cash" onClick={() => onChange?.('Sell')}>
            Sell
          </TriggerButton>
          {!isXs && (
            <TriggerButton iconName="repeat" onClick={() => onChange?.('Sell')}>
              Swap
            </TriggerButton>
          )}
        </Group>
        {!isXs && <SearchIcon />}
      </Card>
    </Center>
  )
}

export default ProductSearchTriggerPill
