import {Image, PaperProps, Text} from 'ui/core'
import {EmptyPhotoCard, Dropzone} from 'core/components'

export interface UploadPhotoCardProps extends Omit<PaperProps, 'children' | 'onChange'> {
  label?: string
  onChange: (file: File) => void
  height?: number
  error?: boolean
  testID?: string
}

const UploadPhotoCard = ({label, error, onChange, height, testID}: UploadPhotoCardProps) => (
  <EmptyPhotoCard
    sx={(theme) => ({borderColor: error ? theme.colors.red[5] : undefined, borderRadius: 0})}
    testID={testID}
    component={Dropzone}
    unstyled
    multiple={false}
    onDrop={([file]: File[]) => onChange(file)}
  >
    <Image
      fit="contain"
      withPlaceholder
      height={height}
      sx={{width: '100%'}}
      placeholder={
        <Text size="xl" align="center">
          {label}
        </Text>
      }
    />
  </EmptyPhotoCard>
)

export default UploadPhotoCard
