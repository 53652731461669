import {createStyles} from 'ui/core'
import {ReactNode} from 'react'
import {formatTestID} from 'ui/utils'

const useStyles = createStyles((theme, {reverseWhenXs}: {reverseWhenXs?: boolean}) => ({
  root: {
    display: 'flex',
    gap: theme.spacing.lg,
    flexDirection: reverseWhenXs ? 'column-reverse' : 'column',
    [theme.fn.largerThan('sm')]: {
      gap: theme.spacing.md,
      flexDirection: 'row',
      justifyContent: 'space-between',
    },
  },
  content: {
    [theme.fn.largerThan('sm')]: {
      flex: 1,
    },
  },
  sidebar: {
    [theme.fn.largerThan('sm')]: {
      width: '300px',
      top: '100px',
      height: '100%',
      position: 'sticky',
    },
  },
}))

interface StickySidebarContentProps {
  content: ReactNode
  sidebar: ReactNode
  reverseWhenXs?: boolean
  testID?: string
}

const StickySidebarContent = ({
  content,
  sidebar,
  reverseWhenXs,
  testID,
}: StickySidebarContentProps) => {
  const {classes} = useStyles({reverseWhenXs})

  return (
    <div className={classes.root} data-testid={testID}>
      <div className={classes.content} data-testid={formatTestID(testID, 'content')}>
        {content}
      </div>
      <div className={classes.sidebar} data-testid={formatTestID(testID, 'sidebar')}>
        {sidebar}
      </div>
    </div>
  )
}

export default StickySidebarContent
