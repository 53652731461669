import {Group, Text, Button, LoadingOverlay, IconName, Icon} from 'ui/core'
import {forwardRef, ReactNode} from 'react'

interface SellRequestActionProps {
  icon: IconName
  title: ReactNode
  component?: any
  loading?: boolean
  onClick?: () => void
}

const SellRequestAction = forwardRef<HTMLButtonElement, SellRequestActionProps>(
  ({title, loading, component, icon, onClick}, ref) => (
    <Button
      ref={ref}
      component={component}
      p="md"
      variant="default"
      radius="lg"
      sx={{flexGrow: 1, height: 62, position: 'relative'}}
      onClick={onClick}
    >
      <Group position="center">
        <Icon name={icon} size={28} />
        <Text align="center">{title}</Text>
      </Group>
      <LoadingOverlay visible={!!loading} />
    </Button>
  ),
)

export default SellRequestAction
