import React, {useState} from 'react'
import {useComponentDefaultProps} from 'ui/styles'
import {ModalBaseDefaultProps, ModalBaseSettings, ModalBase} from 'ui/core'
import {ProductSearchProvider, ScrollAreaComponent} from '../ProductSearch.context'
import {ProductSearchTrigger, ProductSearchTriggerProps} from '../ProductSearchTrigger'

export interface ProductSearchRootProps extends Pick<ModalBaseSettings, 'transitionProps'> {
  scrollAreaComponent?: ScrollAreaComponent
  triggerProps?: Omit<ProductSearchTriggerProps, 'onTrigger'>
}

const defaultProps: Partial<ProductSearchRootProps> = {
  ...ModalBaseDefaultProps,
}

const ProductSearchRoot = (props: ProductSearchRootProps) => {
  const {scrollAreaComponent, transitionProps, triggerProps, ...others} = useComponentDefaultProps(
    'ProductSearchRoot',
    defaultProps,
    props,
  )

  const [isOpen, setIsOpen] = useState(false)

  const openSearch = () => setIsOpen(true)

  const closeSearch = () => setIsOpen(false)

  return (
    <ProductSearchProvider value={{scrollAreaComponent, isOpen, openSearch, closeSearch}}>
      <ProductSearchTrigger {...triggerProps} onTrigger={openSearch} />
      <ModalBase
        __staticSelector="ProductSearchRoot"
        opened={isOpen}
        onClose={closeSearch}
        transitionProps={{duration: 200, ...transitionProps}}
        {...others}
      />
    </ProductSearchProvider>
  )
}

export default ProductSearchRoot
