import {formatTestID, getAgentForCategory} from 'core/utils'
import {Stack} from 'ui/core'
import {AddressLink, DetailItem, PhoneNumber} from 'core/components'

interface AgentInfoProps {
  testID?: string
  category?: string
}

const AgentInfo = ({testID, category = 'default'}: AgentInfoProps) => {
  const agent = getAgentForCategory(category)

  return (
    <Stack spacing="xs">
      <DetailItem label="Contact person" value={agent.firstName} iconName="userCircle" />
      <DetailItem label="Phone number" value={<PhoneNumber inline value={agent.phone} />} />
      <DetailItem
        label="Address"
        value={
          <AddressLink
            placeId={agent.location.googlePlaceId}
            placeName={agent.location.googlePlaceName}
            testID={formatTestID(testID, 'address')}
          />
        }
      />
      <DetailItem label="Operating hours" value="Monday - Saturday. 9am - 5pm." iconName="clock" />
    </Stack>
  )
}

export default AgentInfo
