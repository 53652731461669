import {Card, Group, Button, Stack, Checkbox, Flex} from 'ui/core'
import {useCart} from 'lib/cart'
import {CartItem as CartItemModel} from 'lib/cart/types'
import {DiscountedPrice} from 'core/components'
import {ProductImage} from 'components'
import {QuantitySelect} from '../QuantitySelect'
import {InStock} from '../InStock'
import {CartItemVariants} from '../CartItemVariants'
import {CartItemRemoveButton} from '../CartItemRemoveButton'

interface CartItemProps {
  item: CartItemModel
}

const CartItem = ({item}: CartItemProps) => {
  const cart = useCart()
  const isNaijaUsed = item.product.condition === 'Naija Used'

  return (
    <Card>
      <Flex direction="column" gap="sm">
        <Group spacing="xs" position="apart" align="start">
          <Checkbox
            lh={0}
            label={item.product.title}
            checked={item.checkout}
            onChange={(e) => cart.setItemCheckout(item, e.target.checked)}
          />
          <DiscountedPrice price={item.price} quantity={item.quantity} discount={item.discount} />
        </Group>

        <Group spacing="xs" noWrap>
          <ProductImage size={150} product={item.product} />
          <Stack spacing="sm">
            <div>
              <InStock inStock={item.product.inStock} />
            </div>
            <CartItemVariants variants={item.variants} />
            <Group align="center" spacing="xs">
              <QuantitySelect
                label="Qty"
                withinPortal
                size="sm"
                value={item.quantity}
                sx={{maxWidth: '120px'}}
                disabled={!item.product.inStock || isNaijaUsed}
                styles={{
                  root: {display: 'flex', alignItems: 'center'},
                  wrapper: {maxWidth: '80px', marginLeft: '8px'},
                  label: {marginBottom: '0px'},
                }}
                onChange={(val) => cart.setItemQuantity(item, val)}
              />
              <CartItemRemoveButton<any> item={item} component={Button} size="sm" variant="outline">
                Remove
              </CartItemRemoveButton>
            </Group>
          </Stack>
        </Group>
      </Flex>
    </Card>
  )
}

export default CartItem
