import {SimpleGrid, SimpleGridProps} from 'ui/core'

export interface CatalogueProps extends SimpleGridProps {}

const Catalogue = (props: CatalogueProps) => (
  <SimpleGrid
    spacing="md"
    cols={1}
    breakpoints={[
      {minWidth: 'xs', cols: 2},
      {minWidth: 'md', cols: 3},
      {minWidth: 'lg', cols: 4},
    ]}
    {...props}
  />
)

export default Catalogue
