import {Drawer, Transition, Indicator, Stack, Divider, ScrollArea, IconButton} from 'ui/core'
import {useCart} from 'lib/cart'
import {useEffect} from 'react'
import {rem} from 'ui/styles'
import {getCartItemID} from 'helpers/shop'
import {FloatingCartItem} from '../FloatingCartItem'
import {FloatingCartHeader} from '../FloatingCartHeader'
import {EmptyCart} from '../EmptyCart'

const FloatingCart = () => {
  const cart = useCart()

  const open = () => cart.open()
  const closeDrawer = () => cart.close()

  const hasItems = !cart.isEmpty
  const height = typeof window === 'undefined' ? 900 : window.innerHeight - 144

  useEffect(() => {
    if (!hasItems || cart.isHidden) {
      closeDrawer()
    }
  }, [hasItems, cart.isHidden])

  return (
    <>
      <Transition transition="slide-left" mounted>
        {(transitionStyles) => (
          <Indicator
            label={cart.total.quantity}
            inline
            disabled={!cart.total.quantity}
            size={20}
            position="top-end"
            offset={8}
            style={transitionStyles}
          >
            <IconButton size="xl" variant="light" onClick={open} name="shoppingCart" />
          </Indicator>
        )}
      </Transition>

      <Drawer
        closeOnClickOutside
        withOverlay
        title={cart.isEmpty ? undefined : 'Subtotal'}
        opened={cart.isOpen}
        padding="xs"
        size={rem(180)}
        shadow="xl"
        onClose={closeDrawer}
        position="right"
      >
        {!cart.isEmpty ? (
          <>
            <FloatingCartHeader />
            <Divider my="sm" />
            <ScrollArea
              type="never"
              sx={(theme) => ({
                height,
                [theme.fn.largerThan('xs')]: {
                  paddingBottom: 76,
                },
              })}
            >
              <Stack spacing="sm">
                {cart.items.map((item) => (
                  <FloatingCartItem key={getCartItemID(item)} item={item} />
                ))}
              </Stack>
            </ScrollArea>
          </>
        ) : (
          <EmptyCart h={height} />
        )}
      </Drawer>
    </>
  )
}

export default FloatingCart
