import {useComponentDefaultProps} from 'ui/styles'
import {ModalBase, ModalBaseDefaultProps} from 'ui/core'
import ProductSearchRoot, {ProductSearchRootProps} from './ProductSearchRoot/ProductSearchRoot'
import {ProductSearchContent} from './ProductSearchContent/ProductSearchContent'
import {ProductSearchTriggerProps} from './ProductSearchTrigger'

export interface ProductSearchProps extends Pick<ProductSearchRootProps, 'transitionProps'> {
  triggerProps?: Omit<ProductSearchTriggerProps, 'onTrigger'>
}

const defaultProps: Partial<ProductSearchProps> = {
  transitionProps: {transition: 'scale'},
  ...ModalBaseDefaultProps,
}

const ProductSearch = (props: ProductSearchProps) => {
  const {transitionProps, ...rest} = useComponentDefaultProps('ProductSearch', defaultProps, props)

  return (
    <ProductSearchRoot {...rest}>
      <ModalBase.Overlay />
      <ProductSearchContent transitionProps={transitionProps} />
    </ProductSearchRoot>
  )
}

export default ProductSearch
