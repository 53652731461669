import {BoxProps, createStyles} from 'ui/core'

const useStyles = createStyles((theme) => ({
  item: {
    width: '100%',
    padding: '6px 10px',
    cursor: 'pointer',
    borderRadius: theme.radius.xs,
    '&[aria-selected="true"]': {
      backgroundColor: theme.colors.gray[1],
    },
  },
}))

export interface HitListItemProps extends BoxProps {}

const HitListItem = ({children, ...rest}: HitListItemProps) => {
  const {classes} = useStyles()
  return (
    <li {...rest} className={classes.item}>
      {children}
    </li>
  )
}

export default HitListItem
