import {useAuth} from '@resellam/auth'
import {useEffect, useRef, useState} from 'react'
import {Box, Group, Icon, IconButton, Popover, Text, useMantineTheme} from 'ui/core'
import {useWindowScroll} from 'ui/hooks'
import Bowser from 'bowser'

const ShareIcon = () => {
  const theme = useMantineTheme()
  return (
    <svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 24 24">
      <path d="M0 0h24v24H0V0z" fill="none" />
      <path
        d="M16 5l-1.42 1.42-1.59-1.59V16h-1.98V4.83L9.42 6.42 8 5l4-4 4 4zm4 5v11c0 1.1-.9 2-2 2H6c-1.11 0-2-.9-2-2V10c0-1.11.89-2 2-2h3v2H6v11h12V10h-3V8h3c1.1 0 2 .89 2 2z"
        fill={theme.colors.blue[5]}
      />
    </svg>
  )
}

const InstallPrompt = () => {
  const auth = useAuth()
  const [scroll] = useWindowScroll()
  const scrollYRef = useRef(scroll.y)
  const [opened, setOpened] = useState(false)
  const [browser, setBrowser] = useState<Bowser.Parser.Parser>()
  const [manuallyClosed, setManuallyClosed] = useState(false)
  const innerWidth = typeof window === 'undefined' ? 432 : window.innerWidth

  useEffect(() => {
    setBrowser(Bowser.getParser(window.navigator.userAgent).parse())
  }, [])

  useEffect(() => {
    if (manuallyClosed || auth.loading || !browser) return
    setOpened(
      !auth.user &&
        browser.is('iOS') &&
        browser.is('Safari') &&
        !(window.navigator as any).standalone &&
        (scrollYRef.current >= scroll.y || scroll.y === 0),
    )
    scrollYRef.current = scroll.y
  }, [auth.user, auth.loading, scroll.y, browser])

  return (
    <Box
      sx={{
        position: 'fixed',
        bottom: 0,
        left: '50%',
        transform: 'translateX(-50%)',
        zIndex: 1,
      }}
    >
      <Popover
        withArrow
        withinPortal
        opened={opened}
        arrowSize={20}
        closeOnClickOutside
        width={Math.min(400, innerWidth - 32)}
        position="top"
      >
        <Popover.Target>
          <div />
        </Popover.Target>
        <Popover.Dropdown>
          <Group noWrap spacing="xs" align="center">
            <div>
              <Icon name="apps" size="lg" />
            </div>
            <Text size="sm">
              Install Resellam on your {browser?.getPlatform()?.model || 'Device'}. Tap
              <Box
                component="span"
                sx={{
                  verticalAlign: 'middle',
                  display: '-webkit-inline-flex',
                }}
              >
                <ShareIcon />
              </Box>{' '}
              below, then{' '}
              <Text inline size="sm" variant="link" component="span">
                &quot;Add to Home Screen&quot;
              </Text>
              .
            </Text>
            <IconButton
              name="x"
              variant="outline"
              size="sm"
              onClick={() => {
                setOpened(false)
                setManuallyClosed(true)
              }}
            />
          </Group>
        </Popover.Dropdown>
      </Popover>
    </Box>
  )
}

export default InstallPrompt
