import {Box, Button, Center, Group, Icon, Loader, ScrollArea, Stack} from 'ui/core'
import {Address} from 'model'
import {useAuth} from '@resellam/auth'
import {useModals} from 'ui/modals'
import {useGetDocument, useQueryDocuments} from '@resellam/firebase'
import {AddressForm} from 'core/components'
import analytics from 'lib/analytics'
import {useEffect, useMemo, useRef} from 'react'
import {formatTestID} from 'ui/utils'
import {useIsLoading} from 'core/hooks'
import {ShippingAddress} from '../ShippingAddress'

export interface ShippingAddressesProps {
  value?: Address
  onChange: (value?: Address) => void
  testID?: string
}

const ShippingAddresses = ({value, onChange, testID}: ShippingAddressesProps) => {
  const {user} = useAuth()
  const modals = useModals()
  const onChangeRef = useRef(false)

  const {data: primaryAddress, isLoading: loadingPrimaryAddress} = useGetDocument<Address>(
    {
      collection: 'addresses',
      id: user?.primaryAddressId,
    },
    {
      revalidateIfStale: false,
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
      shouldRetryOnError: false,
      revalidateOnMount: false,
    },
  )

  const handleChange = (newAddress?: Address) => {
    onChangeRef.current = true
    onChange(newAddress)
  }

  useEffect(() => {
    if (primaryAddress && !onChangeRef.current) {
      handleChange(primaryAddress)
    }
  }, [primaryAddress?.id])

  const {
    data: addressesData = [],
    mutate: mutateAddresses,
    isLoading: loadingAddresses,
  } = useQueryDocuments<Address>({
    collection: 'addresses',
    where: useMemo(() => [{field: 'createdById', operator: '==', value: user?.id}], [user?.id]),
    limit: 50,
    skip: !user?.id,
  })

  const addresses = useMemo(
    () =>
      addressesData.sort((a, b) =>
        b.createdAt && a.createdAt ? b.createdAt.getTime() - a.createdAt.getTime() : 0,
      ),
    [addressesData],
  )

  const loading = useIsLoading({
    dependencies: [loadingPrimaryAddress, loadingAddresses],
  })

  const handleAddNewAddress = (newAddress: Address) => {
    analytics.track('add_address', {
      category: 'engagement',
    })
    mutateAddresses((data) => [...(data || []), newAddress])
    handleChange(newAddress)
  }

  const openAddModal = () => {
    const id = modals.openModal({
      zIndex: 201, // important when nesting inside fullscreen modal of buy now button
      title: 'Add Address',
      children: (
        <AddressForm
          onSuccess={(data) => {
            modals.closeModal(id)
            handleAddNewAddress(data)
          }}
        />
      ),
    })
  }

  return (
    <Box data-testid={testID}>
      {loading ? (
        <Center>
          <Loader data-testid={formatTestID(testID, 'loading')} />
        </Center>
      ) : (
        <Stack spacing="xs">
          {value ? (
            <ShippingAddress
              selected
              address={value}
              testID={formatTestID(testID, 'selected-shipping-address')}
            />
          ) : (
            <ScrollArea.Autosize mah={350} type="hover">
              <Stack spacing="xs">
                {addresses.map((item) => (
                  <ShippingAddress
                    key={item.id}
                    address={item}
                    onClick={() => handleChange(item)}
                    testID={formatTestID(testID, 'shipping-address', item.id)}
                  />
                ))}
              </Stack>
            </ScrollArea.Autosize>
          )}
          <Group grow>
            <Button
              size={addresses.length ? 'sm' : 'md'}
              leftIcon={<Icon name="plus" />}
              variant="default"
              onClick={openAddModal}
              data-testid={formatTestID(testID, 'new-address')}
            >
              New Address
            </Button>
            {Boolean(addresses.length > 1 && value) && (
              <Button
                size="sm"
                leftIcon={<Icon name="edit" />}
                variant="default"
                onClick={() => handleChange()}
                data-testid={formatTestID(testID, 'change-address')}
              >
                Change
              </Button>
            )}
          </Group>
        </Stack>
      )}
    </Box>
  )
}

export default ShippingAddresses
