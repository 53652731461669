import {Card, createStyles, Group, Icon, UnstyledButton} from 'ui/core'
import {ReactNode} from 'react'

export interface SelectableCardProps {
  selected?: boolean
  onClick?: () => void
  children: ReactNode
  testID?: string
}

const useStyles = createStyles((theme, {selected}: {selected?: boolean}) => ({
  root: {
    width: '100%',
    flex: 1,
    [theme.fn.largerThan('sm')]: {
      '&:hover': {
        boxShadow: selected ? undefined : `${theme.shadows.xs} !important`,
      },
    },
  },
}))

const SelectableCard = ({children, selected, onClick, testID}: SelectableCardProps) => {
  const {classes} = useStyles({selected})
  return (
    <Card<any>
      component={selected ? 'div' : UnstyledButton}
      className={classes.root}
      onClick={onClick}
      data-testid={testID}
    >
      <Group noWrap position="apart" align="center">
        {children}
        {selected && <Icon name="circleCheck" color="blue" size="xl" />}
      </Group>
    </Card>
  )
}

export default SelectableCard
