import {theme as appTheme} from 'core/utils'
import {createStyles, Text} from 'ui/core'
import Link from 'next/link'
import {Image} from 'core/components'

const useStyles = createStyles((theme) => ({
  root: {
    display: 'block',
    width: '100%',
    backgroundColor: appTheme.colors.secondary.waterBlue,
    borderRadius: theme.spacing.lg,
    position: 'relative',
  },
  caption: {
    lineHeight: 1,
    position: 'absolute',
    bottom: 20,
    left: 20,
    textAlign: 'left',
    fontSize: 32,
    fontWeight: 600,
    color: theme.colors.dark[4],
    [theme.fn.largerThan('sm')]: {
      fontSize: 38,
    },
  },
  image: {
    [theme.fn.largerThan('sm')]: {
      transition: 'transform 400ms ease',
      transform: 'scale(0.9)',
      '&:hover': {
        transform: 'scale(1)',
      },
    },
  },
}))

interface CarouselImageProps {
  href: string
  src: string
  caption: string
  className?: string
}

const CarouselImage = ({href, src, caption, className}: CarouselImageProps) => {
  const {classes, cx} = useStyles()
  return (
    <Link passHref href={href} className={cx(classes.root, className)}>
      <Image fill priority src={src} quality={100} alt={caption} className={classes.image} />
      <Text className={classes.caption}>{caption}</Text>
    </Link>
  )
}

export default CarouselImage
