import {Box, ScrollArea} from 'ui/core'
import {useEffect, useRef, useState} from 'react'
import {AlgoliaProductHit, recentSearchesPlugin} from 'lib/algolia'
import {useRouter} from 'next/router'
import {useBreakpoint} from 'core/hooks'
import {NoResults} from 'core/components'
import {ProductHit} from '../ProductHit'
import {RecentSearchHit} from '../RecentSearchHit'
import {HitListItem} from '../HitListItem'
import {ProductSearchInput} from '../ProductSearchInput'
import {useProductSearch} from '../useProductSearch'
import {getProductURL} from '../utils'
import {SuggestedSearchHit} from '../SuggestedSearchHit'
import {HitList} from '../HitList'

interface ProductSearchFormProps {
  onCancel?: () => void
  onClickProduct?: (item: AlgoliaProductHit) => void
}

const ProductSearchForm = ({onCancel, onClickProduct}: ProductSearchFormProps) => {
  const router = useRouter()
  const inputRef = useRef<HTMLInputElement>(null!)
  const panelRef = useRef<HTMLDivElement>(null!)
  const formRef = useRef<HTMLFormElement>(null!)
  const {isXs} = useBreakpoint()
  const {
    status,
    query,
    products,
    noResults,
    searchType,
    autocomplete,
    recentSearches,
    querySuggestions,
  } = useProductSearch()

  const getItemProps = (item: any, source: any) =>
    autocomplete.getItemProps({
      item,
      source,
    }) as any

  const recentSearchesList = recentSearches?.items?.length ? (
    <HitList {...autocomplete.getListProps()}>
      {recentSearches.items.map((item) => (
        <HitListItem {...getItemProps(item, recentSearches.source)} key={item.id}>
          <RecentSearchHit
            hit={item}
            onDelete={() => {
              recentSearchesPlugin.data?.removeItem(item.id)
              autocomplete.refresh()
            }}
          />
        </HitListItem>
      ))}
    </HitList>
  ) : null

  const querySuggestionsList = querySuggestions?.items?.length ? (
    <HitList {...autocomplete.getListProps()}>
      {querySuggestions.items.map((item) => (
        <HitListItem {...getItemProps(item, querySuggestions.source)} key={item.objectID}>
          <SuggestedSearchHit hit={item} />
        </HitListItem>
      ))}
    </HitList>
  ) : null

  const productsList = products?.items?.length ? (
    <HitList {...autocomplete.getListProps()}>
      {products.items.map((item) => {
        const itemProps = getItemProps(item, products.source)
        return (
          <HitListItem
            {...itemProps}
            key={item.objectID}
            onClick={(e: any) => {
              itemProps.onClick(e)
              onClickProduct?.(item)
            }}
          >
            <ProductHit
              hit={item}
              offline={status === 'stalled'}
              onInit={() => router.prefetch(getProductURL(searchType, item))}
            />
          </HitListItem>
        )
      })}
    </HitList>
  ) : null

  const [scrollHeight, setScrollHeight] = useState(0)

  useEffect(() => {
    const height = formRef.current.clientHeight - (inputRef.current.clientHeight + 48)
    setScrollHeight(height)
  }, [])

  useEffect(() => {
    const {onTouchStart, onTouchMove, onMouseDown} = autocomplete.getEnvironmentProps({
      formElement: formRef.current,
      panelElement: panelRef.current,
      inputElement: inputRef.current,
    })

    window.addEventListener('touchstart', onTouchStart)
    window.addEventListener('touchmove', onTouchMove)
    window.addEventListener('mousedown', onMouseDown)

    return () => {
      window.removeEventListener('touchstart', onTouchStart)
      window.removeEventListener('touchmove', onTouchMove)
      window.removeEventListener('mousedown', onMouseDown)
    }
  }, [autocomplete.getEnvironmentProps])

  return (
    <Box
      {...(autocomplete.getFormProps({inputElement: inputRef.current}) as any)}
      component="form"
      ref={formRef}
      sx={{height: '100%'}}
    >
      <ProductSearchInput ref={inputRef} onCancel={onCancel} />
      <ScrollArea.Autosize
        mah={isXs ? scrollHeight : undefined}
        mt="xs"
        {...(autocomplete.getPanelProps({}) as any)}
        ref={panelRef}
      >
        {recentSearchesList}
        {querySuggestionsList}
        {noResults ? <NoResults query={query} /> : productsList}
      </ScrollArea.Autosize>
    </Box>
  )
}

export default ProductSearchForm
