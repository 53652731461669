import {Carousel as UICarousel} from 'ui/carousel'
import Autoplay from 'embla-carousel-autoplay'
import {useRef} from 'react'
import {createStyles} from 'ui/core'
import {CarouselImage} from '../CarouselImage'

const content = [
  {
    text: 'Phone',
    category: 'Smartphone',
    imageUrl: '/images/hero-phone.png',
  },
  {
    text: 'Laptop',
    category: 'Laptop',
    imageUrl: '/images/hero-laptop.png',
  },
  {
    text: 'Smartwatch',
    category: 'Wearable',
    imageUrl: '/images/hero-smartwatches.png',
  },
  {
    text: 'Tablet',
    category: 'Tablet',
    imageUrl: '/images/hero-tablets.png',
  },
  {
    text: 'Gadgets',
    category: 'Smartphone',
    imageUrl: '/images/hero-gadgets.png',
  },
]

const useStyles = createStyles((theme) => ({
  root: {
    height: 320,
    [theme.fn.largerThan('sm')]: {
      height: 400,
    },
    [theme.fn.largerThan('md')]: {
      height: 460,
    },
    [theme.fn.largerThan('lg')]: {
      height: 480,
    },
  },
}))

const Carousel = () => {
  const {classes} = useStyles()
  const autoplay = useRef(Autoplay({delay: 2000}))

  return (
    <div className={classes.root} style={{display: 'flex'}}>
      <UICarousel
        loop
        align="center"
        withControls={false}
        withIndicators={false}
        slideGap="lg"
        height="100%"
        sx={{flex: 1}}
        slideSize="100%"
        plugins={[autoplay.current]}
        onMouseEnter={autoplay.current.stop}
        onMouseLeave={autoplay.current.reset}
      >
        {content.map((item) => (
          <UICarousel.Slide key={`slide-photo-${item.text}`}>
            <CarouselImage
              className={classes.root}
              src={item.imageUrl}
              caption={item.text}
              href={`/shop?category=${item.category}`}
            />
          </UICarousel.Slide>
        ))}
      </UICarousel>
    </div>
  )
}

export default Carousel
