import {Button, ButtonProps, Radio, RadioProps} from 'ui/core'
import {useRef} from 'react'
import {formatTestID} from 'core/utils'

type RadioButtonProps = ButtonProps &
  Pick<RadioProps, 'value' | 'onChange'> & {
    testID?: string
  }

const RadioButton = ({children, value, onChange, testID, ...rest}: RadioButtonProps) => {
  const ref = useRef<HTMLInputElement>(null)

  return (
    <Button
      {...rest}
      fullWidth
      size="lg"
      variant="default"
      radius="lg"
      onClick={() => ref.current?.click()}
      rightIcon={
        <Radio
          ref={ref}
          value={value}
          onChange={onChange}
          data-testid={formatTestID(testID, 'radio')}
          m={0}
        />
      }
      styles={() => ({
        root: {
          paddingTop: 16,
          paddingBottom: 16,
          paddingLeft: 16,
          paddingRight: 16,
        },
        leftIcon: {
          position: 'absolute',
          left: 16,
        },
        rightIcon: {
          position: 'absolute',
          right: 16,
        },
        label: {
          whiteSpace: 'normal',
        },
      })}
      data-testid={testID}
    >
      {children}
    </Button>
  )
}

export default RadioButton
