import {StepHeader} from 'components'
import analytics from 'lib/analytics'
import {useCheckout} from 'lib/checkout'
import dynamic from 'next/dynamic'
import Link from 'next/link'
import {Button, Flex, Stack, Text} from 'ui/core'

const DynamicSuccessIcon = dynamic(() => import('./SuccessIcon'), {
  suspense: false,
})

interface CheckoutSuccessProps {
  onContinue?: () => void
}

const CheckoutSuccess = ({onContinue}: CheckoutSuccessProps) => {
  const checkout = useCheckout()
  return (
    <>
      <StepHeader title="Order Confirmed" />
      <Stack align="center" py="xl" spacing="xl">
        <DynamicSuccessIcon />
        <Text size="xl" weight={500}>
          We&apos;re getting your order ready
        </Text>
        <Flex direction={{base: 'column', sm: 'row'}} gap="md" w="100%">
          <Button
            fullWidth
            component={Link}
            href="/shop"
            replace
            variant="outline"
            size="lg"
            onClick={() => {
              analytics.track('shop_continue_shopping', {
                category: 'engagement',
              })
              onContinue?.()
            }}
          >
            Continue Shopping
          </Button>
          {checkout.createdOrder?.id && (
            <Button
              component={Link}
              href={`/orders/${checkout.createdOrder?.id}`}
              replace
              fullWidth
              size="lg"
              onClick={() => {
                analytics.track('shop_view_order', {
                  category: 'engagement',
                })
                onContinue?.()
              }}
            >
              View Order
            </Button>
          )}
        </Flex>
      </Stack>
    </>
  )
}

export default CheckoutSuccess
