import {Product, SellRequestVariant} from 'model'
import {formatTestID, isAppleSmartphone} from 'core/utils'
import {Money, ProductDetailWrapper} from 'core/components'
import {
  useSellRequestDetailsForm,
  SellRequestDetailsForm,
  SellRequestDetailsFormProvider,
} from '@resellam/sell-request'
import {Alert, Button, Collapse, Stack, Text} from 'ui/core'
import {useCallback, useState} from 'react'
import {useDidUpdate} from 'ui/hooks'
import useEstimateProductPrice from 'hooks/use-estimate-product-price'
import {trackProducts} from 'lib/analytics'
import {DetailsFormData} from '../types'

export interface EstimateProps {
  product?: Product
  loading?: boolean
  estimate?: boolean
  onNext: (data: DetailsFormData) => void
  testID?: string
}

const DetailsForm = ({
  product,
  loading,
  estimate: _estimate = true,
  testID,
  onNext,
}: Omit<EstimateProps, 'initialValues'>) => {
  const [estimate, setEstimate] = useState<(DetailsFormData & {price: null | number}) | null>(null)
  const {form, schema} = useSellRequestDetailsForm()
  const [estimateProductPrice, estimateProductPriceState] = useEstimateProductPrice()

  useDidUpdate(() => {
    form.reset()
  }, [product?.id])

  useDidUpdate(() => {
    setEstimate(null)
  }, [form.values, product?.id])

  const track = (eventName: string, variants?: SellRequestVariant[]) =>
    trackProducts(eventName, {
      products: product
        ? [
            {
              ...product,
              variant: variants?.map((variant) => variant.value).join(', '),
            },
          ]
        : undefined,
    })

  const handleContinue = useCallback(() => {
    if (form.validate().hasErrors) return

    const values = schema.parse(form.values)

    onNext({
      batteryHealth: values.batteryHealth,
      variants: values.variants || [],
    })
  }, [onNext, form])

  const getPrice = () => {
    if (!estimate) return

    track('get_product_exact_price', estimate.variants)

    onNext({
      batteryHealth: estimate.batteryHealth,
      variants: estimate.variants,
    })
  }

  const getEstimate = async () => {
    if (!product || form.validate().hasErrors) return

    const values = schema.parse(form.values)

    track('get_product_price_estimate', values.variants)

    const price = await estimateProductPrice({product, ...values})

    if (price) {
      setEstimate({
        batteryHealth: values.batteryHealth,
        variants: values.variants || [],
        price,
      })
      form.resetDirty()
    } else {
      onNext({
        batteryHealth: values.batteryHealth,
        variants: values.variants || [],
      })
    }
  }

  return (
    <ProductDetailWrapper loading={!product} product={product}>
      <Stack spacing="xl" data-testid={testID}>
        {(!!product?.variants?.length || isAppleSmartphone(product) || loading) && (
          <SellRequestDetailsForm spacing="xl" product={product} testID={testID} />
        )}

        {_estimate ? (
          <>
            <Collapse in={!!estimate} transitionTimingFunction="slide-up">
              <Alert
                radius="lg"
                p="md"
                title={<Text size="lg">Get Up To</Text>}
                data-testid={formatTestID(testID, 'estimated-price')}
              >
                {estimate?.price && (
                  <Money
                    value={estimate?.price || 0}
                    align="center"
                    weight="bold"
                    size="xl"
                    data-testid={formatTestID(testID, 'price')}
                  />
                )}
                <Button
                  mt="md"
                  fullWidth
                  size="lg"
                  onClick={getPrice}
                  data-testid={formatTestID(testID, 'get-exact-price')}
                >
                  Continue
                </Button>
              </Alert>
            </Collapse>

            {!estimate && (
              <Button
                fullWidth
                size="lg"
                disabled={!product}
                loading={estimateProductPriceState.isRunning}
                onClick={getEstimate}
                data-testid={formatTestID(testID, 'get-estimated-price')}
              >
                Get Price
              </Button>
            )}
          </>
        ) : (
          <Button
            fullWidth
            size="lg"
            onClick={handleContinue}
            data-testid={formatTestID(testID, 'continue-no-estimate')}
          >
            Continue
          </Button>
        )}
      </Stack>
    </ProductDetailWrapper>
  )
}

const Details = ({product, ...rest}: EstimateProps) => (
  <SellRequestDetailsFormProvider product={product}>
    <DetailsForm product={product} {...rest} />
  </SellRequestDetailsFormProvider>
)

export default Details
