import {Button, Group, Icon, Text} from 'ui/core'
import {useConnector, useCurrentRefinements, useInstantSearch} from 'react-instantsearch-hooks-web'
import connectStats from 'instantsearch.js/es/connectors/stats/connectStats'
import type {
  StatsConnectorParams,
  StatsWidgetDescription,
} from 'instantsearch.js/es/connectors/stats/connectStats'

type UseStatsProps = StatsConnectorParams

export const useStats = (props?: UseStatsProps) =>
  useConnector<StatsConnectorParams, StatsWidgetDescription>(connectStats, props)

const Stats = () => {
  const stats = useStats()
  const instantSearch = useInstantSearch()
  const currentRefinements = useCurrentRefinements()

  const {query} = instantSearch.indexUiState

  const clearFilters = () => {
    instantSearch.setIndexUiState({query: undefined})
  }

  if ((!currentRefinements.items.length && !query) || instantSearch.status === 'stalled')
    return <div />

  return (
    <Group spacing="xs">
      <Text>
        Showing{' '}
        <Text inline component="span" weight="bold">
          {stats.nbHits}{' '}
          <Text inline weight="normal" component="span">
            {query ? 'results for' : 'results'}
          </Text>{' '}
          {query && (
            <Text inline component="span">
              {query}
            </Text>
          )}
        </Text>
      </Text>
      {query && (
        <Button
          compact
          variant="outline"
          size="sm"
          leftIcon={<Icon name="x" size="sm" />}
          display="inline-flex"
          onClick={clearFilters}
          styles={{leftIcon: {marginRight: 4}}}
        >
          Clear
        </Button>
      )}
    </Group>
  )
}

export default Stats
