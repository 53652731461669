import {Image, PaperProps, IconButton} from 'ui/core'
import {EmptyPhotoCard, PhotoBadge} from 'core/components'
import {useMemo} from 'react'

export interface PhotoCardProps extends Omit<PaperProps, 'children' | 'onChange'> {
  file: File
  label?: string
  height?: number
  onDelete?: () => void
  testID?: string
}

const PhotoCard = ({file, label, height, testID, onDelete}: PhotoCardProps) => {
  const src = useMemo(() => URL.createObjectURL(file), [file])
  return (
    <EmptyPhotoCard hasPhoto testID={testID}>
      <>
        {label && <PhotoBadge>{label}</PhotoBadge>}
        <Image src={src} height={height} imageProps={{onLoad: () => URL.revokeObjectURL(src)}} />
        {onDelete && (
          <IconButton
            name="trash"
            title="Delete"
            size="lg"
            iconSize="md"
            color="red"
            variant="light"
            onClick={onDelete}
            sx={{position: 'absolute', top: '16px', right: '16px', zIndex: 1}}
          />
        )}
      </>
    </EmptyPhotoCard>
  )
}

export default PhotoCard
