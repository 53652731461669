import {AppProps} from 'next/app'
import Head from 'next/head'
import {FeatureFlag} from 'core/services'
import {AuthProvider, LOGIN_PAGE_URL, ROOT_URL} from '@resellam/auth'
import {CoreProvider} from 'core/components'
import {SWRConfig} from 'swr'
import type {ReactElement, ReactNode} from 'react'
import type {NextPage} from 'next'
import {logger} from '@resellam/logger'
import {DefaultSeo, AppLayout, ProductSearchProvider} from 'components'
import dynamic from 'next/dynamic'

const DynamicLiveChat = dynamic(() => import('../components/LiveChat/LiveChat'), {
  suspense: false,
})

const DynamicAnalytics = dynamic(() => import('../components/Analytics/Analytics'), {
  suspense: false,
})

const DynamicAccountSetup = dynamic(() => import('../components/AccountSetup/AccountSetup'), {
  suspense: false,
})

type NextPageWithLayout = NextPage & {
  getLayout?: (page: ReactElement) => ReactNode
}

type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout
}

const App = ({Component, pageProps}: AppPropsWithLayout) => {
  const getLayout = Component.getLayout ?? ((page) => <AppLayout>{page}</AppLayout>)

  return (
    <>
      <Head>
        <meta
          name="viewport"
          content="minimum-scale=1, initial-scale=1, width=device-width, shrink-to-fit=no, user-scalable=no, viewport-fit=cover"
        />
      </Head>

      <DefaultSeo />

      <SWRConfig value={{onError: (err, key) => logger.error(err, key)}}>
        <FeatureFlag defaults={{payments: true, freeDelivery: true}}>
          <AuthProvider publicPages={[ROOT_URL, LOGIN_PAGE_URL, '/sell', '/faqs', '/shop']}>
            <CoreProvider>
              <ProductSearchProvider>
                {getLayout(<Component {...pageProps} />)}
              </ProductSearchProvider>
              <DynamicAccountSetup />
            </CoreProvider>
            <DynamicLiveChat />
            <DynamicAnalytics />
          </AuthProvider>
        </FeatureFlag>
      </SWRConfig>
    </>
  )
}

export default App
