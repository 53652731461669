import {Text, LoadingOverlay, Box, Select, FormField} from 'ui/core'
import {zodResolver} from 'ui/form'
import {logger} from '@resellam/logger'
import {Money} from 'core/components'
import {Address} from 'model'
import {usePickupEstimate} from 'lib/errandlr'
import {useForm, useNotifications} from 'core/hooks'
import {z} from 'zod'
import analytics from 'lib/analytics'
import {addressSchema, formatTestID, getAgentForCategory} from 'core/utils'
import {useSellRequest} from '@resellam/sell-request'
import {StepNavButtons, ShippingAddresses} from 'components'
import {useMemo, useState} from 'react'
import dayjs from 'dayjs'
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore'
import {formatDeliveryDate} from '../../SellRequestStatus.utils'

dayjs.extend(isSameOrBefore)

type EstimateData = {
  date: Date
  amount: number
  address: Address
}

interface SellRequestPickupEstimateProps {
  onNext: (data: EstimateData) => void
  testID?: string
}

const schema = ({minDate, maxDate}: {minDate: Date; maxDate: Date}) =>
  z.object({
    date: z.date({required_error: 'Select pickup date'}).min(minDate).max(maxDate),
    address: addressSchema({required_error: 'Select address'}).extend({
      id: z.string(),
    }),
  })

const SellRequestPickupEstimate = ({onNext, testID}: SellRequestPickupEstimateProps) => {
  const notifications = useNotifications()
  const {sellRequest} = useSellRequest()
  const [getPickupEstimate, getPickupEstimateState] = usePickupEstimate()
  const [estimateData, setEstimateData] = useState<EstimateData | null>(null)

  const minDate = dayjs()
    .add(dayjs().hour() < 17 ? 0 : 1, 'day')
    .startOf('day')

  const maxDate = (
    sellRequest?.offer?.expiresAt ? dayjs(sellRequest?.offer?.expiresAt) : dayjs().add(1, 'week')
  ).endOf('day')

  const selectDays = useMemo(() => {
    const options = []
    let currentDate = minDate.clone()
    while (currentDate.isSameOrBefore(maxDate)) {
      if (currentDate.day() !== 0) {
        options.push({
          label: formatDeliveryDate(currentDate),
          value: currentDate.toISOString(),
        })
      }
      currentDate = currentDate.add(1, 'day')
    }
    return options
  }, [minDate.toISOString(), maxDate.toISOString()])

  const form = useForm<{date?: Date; address?: Address}>({
    initialValues: {},
    validate: zodResolver(
      schema({
        minDate: minDate.toDate(),
        maxDate: maxDate.toDate(),
      }),
    ),
  })

  const estimate = async (values: typeof form.values) => {
    if (form.validate().hasErrors) return

    analytics.track('get_sell_request_pickup_estimate', {
      category: 'ecommerce',
    })

    const category = sellRequest?.product?.category?.slug
    const {address, date} = values as Required<typeof form.values>

    const agent = getAgentForCategory(category)
    if (!address.googlePlaceId) throw new Error('Invalid address')

    const result = await getPickupEstimate({
      pickupLocation: {
        id: address.googlePlaceId,
        name: address.street,
      },
      dropoffLocation: {
        id: agent.location.googlePlaceId,
        name: agent.location.googlePlaceName,
      },
    })

    if (result?.data) {
      setEstimateData({
        date,
        address,
        amount: result?.data.estimate,
      })
    } else {
      logger.error(result, 'Failed to get estmate')
      notifications.show({
        variant: 'error',
        message: 'Failed to get estmate',
      })
    }
  }

  return (
    <Box component="form" pos="relative" onSubmit={form.onSubmit(estimate)} data-testid={testID}>
      <LoadingOverlay visible={getPickupEstimateState.isRunning} />
      {estimateData ? (
        <Text py="md" align="center" data-testid={formatTestID(testID, 'amount')}>
          Price <Money size="xl" weight="bold" value={estimateData.amount} />
        </Text>
      ) : (
        <>
          <Select
            required
            label="Date"
            placeholder="Pickup date"
            {...form.getInputProps('date')}
            value={form.values.date?.toISOString()}
            onChange={(val) => (val ? form.setFieldValue('date', new Date(val)) : null)}
            data={selectDays}
            data-testid={formatTestID(testID, 'date')}
          />
          <FormField required mt="md" label="Address" {...form.getInputProps('address')}>
            <ShippingAddresses
              {...form.getInputProps('address')}
              testID={formatTestID(testID, 'address')}
            />
          </FormField>
        </>
      )}
      <StepNavButtons
        size="md"
        back={
          estimateData
            ? {
                onClick: () => setEstimateData(null),
              }
            : undefined
        }
        next={
          estimateData
            ? {
                onClick: () => onNext(estimateData),
              }
            : {
                type: 'submit',
                loading: getPickupEstimateState.isRunning,
                children: 'Get Price',
                disabled: !form.isValid(),
              }
        }
        testID={formatTestID(testID, 'step-nav')}
      />
    </Box>
  )
}

export default SellRequestPickupEstimate
