import {Box, MediaQuery, SegmentedControl, Text} from 'ui/core'
import {SearchIcon} from '../SearchIcon'
import {SearchType} from '../types'

const Segment = ({label}: {label: string}) => (
  <Box sx={{height: '100%'}}>
    <Text>{label}</Text>
    <MediaQuery smallerThan="xs" styles={{display: 'none'}}>
      <SearchIcon
        sx={{position: 'absolute', right: 4, top: '50%', transform: 'translateY(-50%)'}}
      />
    </MediaQuery>
  </Box>
)

export interface ProductSearchTriggerSegmentProps {
  id?: string
  onlyType?: SearchType
  onlyTypeLabel?: string
  value?: SearchType
  onChange?: (val: SearchType) => void
}

const ProductSearchTriggerSegment = ({
  id,
  value,
  onlyType,
  onlyTypeLabel,
  onChange,
}: ProductSearchTriggerSegmentProps) => {
  const data = onlyType
    ? [
        {
          value: onlyType,
          label: <Segment label={onlyTypeLabel || onlyType} />,
        },
      ]
    : [
        {label: 'Buy', value: 'Buy'},
        {
          value: 'Sell',
          label: <Segment label="Sell" />,
        },
      ]

  const actualValue = onlyType || value

  return (
    <SegmentedControl
      id={id}
      fullWidth
      radius="xl"
      value={actualValue}
      onClick={(e: any) => {
        if (!e.target.value) return
        onChange?.(actualValue || 'Buy')
      }}
      onChange={(val) => onChange?.(val as SearchType)}
      data={data}
      styles={(theme) => ({
        label: {height: '100%', color: theme.colors.blue[6]},
      })}
    />
  )
}

export default ProductSearchTriggerSegment
