import {Card, Text, Group, Stack} from 'ui/core'
import {CartItem} from 'lib/cart/types'
import {DiscountedPrice} from 'core/components'
import {ProductImage} from 'components'
import {CartItemVariants} from '../CartItemVariants'

interface CheckoutItemProps {
  item: CartItem
}

const CheckoutItem = ({item}: CheckoutItemProps) => (
  <Card>
    <Group position="apart">
      <Group noWrap>
        <ProductImage size={80} product={item.product} />
        <Stack spacing="xs">
          <Text lineClamp={1}>
            <Text inline component="span" size="sm">
              ({item.quantity}x)
            </Text>{' '}
            {item.product.title}
          </Text>
          <DiscountedPrice
            direction="row"
            price={item.price}
            quantity={item.quantity}
            discount={item.discount}
          />
          <CartItemVariants variants={item.variants} />
        </Stack>
      </Group>
    </Group>
  </Card>
)

export default CheckoutItem
