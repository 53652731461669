import {Box, Text} from 'ui/core'
import {useSellRequest} from '@resellam/sell-request'
import {ReactNode} from 'react'
import {formatTestID, getAgentForCategory} from 'core/utils'
import {MapEmbed} from 'components'
import {Address} from 'model'

interface SellRequestDeliveryOptionProps {
  title: string
  children: ReactNode
  pickupAddress?: Address
  testID?: string
}

const SellRequestDeliveryOption = ({
  testID,
  title,
  children,
  pickupAddress,
}: SellRequestDeliveryOptionProps) => {
  const {sellRequest} = useSellRequest()
  const category = sellRequest?.product?.category || 'smartphone'
  const agent = getAgentForCategory(category as string)

  return (
    <div data-testid={testID}>
      <Text size="xl" weight={500} data-testid={formatTestID(testID, 'title')}>
        {title}
      </Text>
      <Box mt="xs" mb="md" data-testid={formatTestID(testID, 'children')}>
        {children}
      </Box>
      {pickupAddress ? (
        <MapEmbed
          mode="directions"
          origin={`place_id:${pickupAddress?.googlePlaceId}`}
          destination={`place_id:${agent.location.googlePlaceId}`}
          h={180}
          testID={formatTestID(testID, 'map-directions')}
        />
      ) : (
        <MapEmbed
          mode="place"
          q={`place_id:${agent.location.googlePlaceId}`}
          h={180}
          testID={formatTestID(testID, 'map-place')}
        />
      )}
    </div>
  )
}

export default SellRequestDeliveryOption
