import Link from 'next/link'
import {Button, Group, Icon, Title} from 'ui/core'

export interface SectionTitleProps {
  title: string
  rightAction?: {
    href: string
    title: string
  }
}

const SectionTitle = ({title, rightAction}: SectionTitleProps) => (
  <Group mb="xs" noWrap position="apart" align="center">
    <Title lineClamp={1} order={3}>
      {title}
    </Title>
    {rightAction && (
      <Button
        component={Link}
        href={rightAction.href}
        compact
        variant="subtle"
        rightIcon={<Icon name="arrowRight" />}
      >
        {rightAction.title}
      </Button>
    )}
  </Group>
)

export default SectionTitle
