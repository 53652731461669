import {createStyles, rem} from 'ui/styles'

const useStyles = createStyles((theme) => ({
  content: {
    width: Math.min(600, typeof window === 'undefined' ? 300 : window.innerWidth - 32),
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: rem(16),
    [theme.fn.smallerThan('xs')]: {
      width: '100%',
      marginTop: rem(0),
      height: '100%',
      borderRadius: 0,
    },
  },
}))

export default useStyles
