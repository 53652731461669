import {Box, BoxProps, SimpleGrid} from 'ui/core'
import {Product} from 'model'
import {DetailItem, SectionHeader} from 'core/components'

export interface ProductFeaturesProps extends BoxProps {
  product: Product
  testID?: string
}

const ProductFeatures = ({product, testID, ...rest}: ProductFeaturesProps) => (
  <Box {...rest} data-testid={testID}>
    <SectionHeader title="Features" />
    <SimpleGrid
      cols={1}
      breakpoints={[
        {minWidth: 'xs', cols: 2},
        {minWidth: 'md', cols: 3},
        {minWidth: 'lg', cols: 4},
      ]}
    >
      {product.metas?.map((meta) => (
        <DetailItem
          key={meta.name}
          label={meta.name || 'Unknown'}
          value={meta.value || 'Unknown'}
        />
      ))}
    </SimpleGrid>
  </Box>
)

export default ProductFeatures
